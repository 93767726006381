<template>
  <div class="span">
    <van-nav-bar
      :title=title
      placeholder
      fixed
      :border="false"
    >
    </van-nav-bar>
    <div class="body">
      <van-overlay :show="show !== 0" />
      <div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-count="10"
          :after-read="afterRead"
          :before-read="beforeRead"
        >
          <el-button type="primary" class="button">相册/拍照</el-button>
        </van-uploader>

        <el-button
          type="success"
          @click="upload"
          :loading="show !== 0"
          class="button"
        >开始上传
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";
import { Vue } from "vue-property-decorator";

export default {
  name: "Scan",
  data() {
    return {
      title: Vue.prototype.GLOBAL + '-来料图上传工具',
      fileList: [],
      show: 0
    };
  },
  created() {},
  methods: {
    afterRead(file) {},
    //前置
    beforeRead(file) {
      const query = this.$route.query;
      const accessToken = query.accessToken;
      const fileList = file.length ? file : [file];
      this.show = fileList.length;
      //得到阿里云上传接口
      axios
        .post("/file", null, {
          baseURL: process.env.VUE_APP_MNG_PORT,
          params: {
            contentType: "image/png",
            method: "PUT",
            size: fileList.length
          },
          headers: {
            Authorization: "bearer " + accessToken
          }
        })
        .then(file_res => {
          for (let i = 0; i < file_res.data.length; i++) {
            const f = fileList[i];
            const k = file_res.data[i].key;
            axios
              .put(file_res.data[i].url, f, {
                headers: {
                  "Content-Type": "image/png"
                }
              })
              .then(() => {
                f.key = k;
                this.show = this.show - 1;
              });
          }
        });
      return true;
    },
    //点击上按钮
    upload() {
      if (this.fileList.length === 0) {
        Toast.fail("请选择图片");
        return;
      }
      this.show = 0;
      const query = this.$route.query;
      const uuid = query.uuid;
      const accessToken = query.accessToken;

      const fileKeys = this.fileList.map(fileMap => {
        return {
          key: fileMap.file.key
        };
      });

      axios
        .post(
          "/incoming/confirmUploadIncomingImage",
          {
            uuid: uuid,
            images: fileKeys
          },
          {
            baseURL: process.env.VUE_APP_MNG_PORT,
            headers: {
              Authorization: "bearer " + accessToken
            }
          }
        )
        .then(() => {
          this.fileList = [];
          this.show = 0;
          Toast.success("上传成功");
        })
        .catch(err => {
          alert(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped="true">
::v-deep {
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      background: #39394d;
      .van-nav-bar__title{
        font-size: 36px;
        color: #F6D0AB;
      }
      .van-icon{
        font-size: 40px;
        color: #F6D0AB;
      }
    }
  }
}
body {
  background-color: #d6d6d8;
}

.button {
  width: 100vw;
  height: 20vw;
  background-color: #f2aa2e;
  color: black;
  font-size: 25px;
  border: black 1px solid;
}

.van-uploader__preview {
  margin: 0;
}

.van-uploader__preview-image {
  /* height: auto; */
  width: 25vw;
}

h3 {
  color: #ffffff;
}
</style>
